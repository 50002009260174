/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
  useDispatch,
  useSelector
} from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Button, Col, Form, Radio, Row, Space } from 'antd';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';

import { getStepOptimisationPortfolio, postStepOptimisationPortfolio } from '../../data/store/portfolio/portfolioActions';
import Container from '../../components/Container';
import { Deposits } from './Deposits';
import { Withdrawal } from './Withdrawal';

import ModalUnsaveData from '../../components/ModalUnsaveData/ModalUnsaveData';
import MainFooter from '../../components/MainFooter';

import './styles.scss';

const FinancialPlanning = ({ userId, portfolioId, needSave, setNeedSave, saveAlert, setSaveAlert }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('');

  const [modalCancelVisible, setModalCancelVisible] = useState(false);

  const { plan } = useSelector((state) => state.portfolioReducer);

  const initial = {
    monetary_basis: '',
    deposits: [],
    withdrawals: [],
  };

  useEffect(() => {
    saveAlert && saveAlert.flag && setModalCancelVisible(true);
  }, [saveAlert]);

  useEffect(() => {
    if (portfolioId) dispatch(getStepOptimisationPortfolio({ portfolioId }));
  }, [portfolioId]);

  const dataParsArray = (data) => {
    if (data?.length) {
      const newData = data.map(item => {
        const startDate = dayjs().set('year', item?.from_date.year).set('month', item?.from_date.month).startOf('month');
        const endDate = dayjs().set('year', item?.to_date.year).set('month', item?.to_date?.month).startOf('month');
        return ({ ...item, start: startDate, end: endDate }
        );
      });
      return newData;
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (plan && Object.keys(plan)?.length) {
      form.setFieldsValue({
        ...initial,
        monetary_basis: plan?.monetary_basis?.toString(),
        deposits: dataParsArray(plan?.deposits),
        withdrawals: dataParsArray(plan?.withdrawals),
      });
    }
  }, [plan]);

  const dataForQuant = (data) => {
    const arrayQuatn = (array) => {
      return data[array]?.map(item => (
        {
          type: +item.type,
          description: item.description,
          amount: item?.amount || 0,
          from_date: {
            month: dayjs(item.start).month(),
            year: dayjs(item.start).year()
          },
          to_date: {
            month: dayjs(item.end).month(),
            year: dayjs(item.end).year()
          }
        }
      ));
    };

    const staticData = {
      deposits: arrayQuatn('deposits'),
      withdrawals: arrayQuatn('withdrawals'),
    };
    return { monetary_basis: +data.monetary_basis, ...staticData };
  };

  const handleChange = (changedFields, allFields) => {
    if (changedFields?.length) {
      let newData = form.getFieldsValue();
      if (!newData) {
        newData = initial;
      }
      if (!changedFields[0].name.includes('deposits') && !changedFields[0].name.includes('withdrawals')) {
        newData[changedFields[0].name[0]] = changedFields[0]?.value;
      } else if (changedFields[0].name?.length !== 1) {
        const nameCgangedfield = changedFields[0].name[0];
        const newSubArray = allFields.find(i => i.name.includes(nameCgangedfield) && i.name.length !== 3)?.value;
        newData[nameCgangedfield] = newSubArray;
      }
    }
  };

  const handleValueChange = (_, allValues) => {
    setNeedSave(hasChanges(allValues));
  };

  function hasChanges (allValues) {
    const newData = dataForQuant(allValues);
    const { monetary_basis, deposits, withdrawals } = newData;
    if (
      Number(monetary_basis) === plan.monetary_basis &&
      deposits?.length === plan.deposits?.length &&
      withdrawals?.length === plan.withdrawals?.length &&
      isEqual(plan, newData)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const closeModal = () => {
    setSaveAlert({ flag: false, path: '' });
    setModalCancelVisible(false);
  };

  const cancelWithoutChange = () => {
    const path = saveAlert.path;
    setNeedSave(false);
    dispatch(getStepOptimisationPortfolio({ portfolioId }));

    closeModal();
    history.push(path);
  };

  const handleSubmit = () => {
    form.submit();
  };

  const onSubmit = (data) => {
    const filteredDeposit = data?.deposits?.filter(i => i?.amount && i?.description);
    const filteredWithdrawals = data?.withdrawals?.filter(i => i?.amount && i?.description);

    const newData = dataForQuant(
      {
        monetary_basis: data.monetary_basis,
        deposits: filteredDeposit,
        withdrawals: filteredWithdrawals
      });
    dispatch(
      postStepOptimisationPortfolio({ portfolioId, data: { plan: newData } })
    );
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    closeModal();
  };

  const handleReset = () => {
    form.setFieldsValue({
      ...initial,
      monetary_basis: plan?.monetary_basis?.toString(),
      deposits: dataParsArray(plan?.deposits),
      withdrawals: dataParsArray(plan?.withdrawals),
    });
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
  };

  return (
    <>
      <div className="top-btn-wrapper">
        <Button
          disabled={!needSave}
          className="portfolio-details-btn portfolio-export-btn"
          onClick={handleReset}
        >
          {t('CANCEL')}
        </Button>
        <Button
          disabled={!needSave}
          onClick={handleSubmit}
          className="portfolio-details-btn btn-edit-save"
        >
          {t('SAVE')}
        </Button>
      </div>
      <Form
          id='financial-planning-form'
          form={form}
          initialValues={initial}
          onFieldsChange={(changedFields, allFields) => handleChange(changedFields, allFields)}
          onValuesChange={handleValueChange}
          onFinish={onSubmit}
        >
        <Container id='financial-planning' className='portfolio-container financial-planning'>
        <Row gutter={8}>
          <Col span={24}>
            <h2 className='portfolio-container-title text-left'>{t('SETTINGS')}</h2>
          </Col>
        </Row>
          <div className='opt-portfolio-form-container'>
          <label className="opt-portfolio-form-subheading font12">
            {t('MONET_BASIS')}
          </label>
          <Form.Item
            name={'monetary_basis'}
            className="m-top-12 m-bottom-0 o"
          >
            <Radio.Group
              className="plan-form-radio"
            >
              <Space direction="vertical">
                <Radio value="1">{t('NOMINAL')}</Radio>
                <Radio value="2">{t('REAL')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          </div>
        </Container>
        <Deposits form={form} />
        <Withdrawal form={form} />
      </Form>
        <ModalUnsaveData
          open={modalCancelVisible}
          closeModal={closeModal}
          cancelWithoutChange={cancelWithoutChange}
          onSubmit={handleSubmit}
        />
        <MainFooter />
    </>
  );
};
export default FinancialPlanning;
FinancialPlanning.propTypes = {
  userId: PropTypes.string,
  portfolioId: PropTypes.string,
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};
