export const PortfolioEN = {
  EDIT: 'Edit',
  HISTORY: 'History',
  EXPORT: 'Export to Excel',
  EXPORT_PORT: 'Export',
  DRAFT_PORTFOLIO: 'Draft portfolio',
  CURRENT_PORTFOLIO: 'Current portfolio',
  DELETE: 'Delete',
  LAUNCH_CITRONEER_QUANT: 'Launch Citroneer Quant',
  OPTIMIZE: 'Optimize',
  PORTFOLIO: 'Portfolio',
  PORTFOLIOS: 'Portfolios',
  REPORT: 'Report',
  CLIENTS_LABEL: 'CLIENT',
  ASSET_CATEGORY: 'Asset Category',
  CATEGORY: 'Category',
  ASSET_CLASS: 'Asset Class',
  INSTRUMENT: 'Instrument',
  ISIN: 'ISIN',
  VALUE: 'Value',
  FEE: 'Fee',
  ASSET_ALLOCATION: 'Asset allocation in percent',
  CANCEL: 'Cancel',
  ADD: 'Add',
  IMPORT_FROM_EXCEL: 'Import from Excel',
  SAVE: 'Save',
  OPERATION: 'Operation',
  UNITS: 'No. of Shares',
  SHARE_PRICE: 'Share Price',
  CURRENCY: 'Currency',
  CREATE_PORTFOLIO: 'Create portfolio',
  EDIT_DRAFT_PORTFOLIO: 'Edit draft portfolio',
  NO_PORTFOLIO: 'No portfolio',
  NO_PORTFOLIO_TEXT:
    'It looks like you do not have any portfolio, please edit your draft portfolio or create a new one',
  ADD_INSTRUMENT: 'Add instrument',
  INSTRUMENTS_TAB: 'Instruments',
  ALL_ADVISORS: 'All advisors',
  ACTIVATE_ADVISOR: ' Activate advisor',
  CREATE_ADVISOR: 'Create advisor',
  INVITE_ADVISOR: 'Invite advisor',
  INVITE: 'Invite',
  ADVISOR_LIST: 'Advisor list',
  APPROVE: 'Approve',
  HISTORY_EMPTY: 'History is empty',
  GO_PORTFOLIO: 'Go portfolio',
  UPLOAD_REPORT: 'Upload report',
  DOWNLOAD: 'Download',
  REPORTS: 'Reports',
  CHOOSE_FILE: 'Choose file',
  NO_FILE_CHOSEN: 'No file chosen',
  UPLOAD: 'Upload',
  NAME: 'Name',
  SYS_NAME: 'Sys name',
  CREATE: 'Create',
  CREATE_INSTRUMENT: 'Create instrument',
  ENTER_REPORT_NAME: 'Enter report name',
  TBD_TOOLTIP_TEXT:
    'This instrument is currently not mapped. The' +
    ' Citroner team has been notified and will mapp the instrument ' +
    'as soon as possible.' +
    ' As for know the portfolio can only be saved as a draft but when ' +
    'the instrument is mapped the portfolio will automatically become verified.',
  INSTRUMENTS_REQUESTS: 'Instruments requests',
  SENT_SUCCESSFULLY: 'Portfolio sent successfully',
  REQUEST_FOR_OPTIMIZATION:
    'Request for optimization has already been sent to Admin',
  TOTAL_PORTFOLIO_VALUE: 'Total portfolio value',
  TOTAL: 'Total',
  CREATED: 'Created',
  LAST_MODIFIED: 'Last modified',
  REPLACE_DRAFT: 'Replace draft?',
  REPLACE_CURRENT: 'Replace current',
  REPLACE_DRAFT_TEXT: 'Do you really want to replace the draft portfolio?',
  REPLACE_CURRENT_TEXT: 'Do you really want to replace the current portfolio?',
  IMPORT: 'Import',
  SYS_NAME_UPPER: 'SYS_NAME',
  PORTFOLIO_INFORMATION: 'Portfolio Information',
  CONTINUE: 'Continue',
  ENTER_THE_NAME: 'Enter the name',
  AVAILABLE_CASH: 'Available cash',
  TAX_RATE: 'Tax rate',
  DEPOSIT_FEE: 'Deposit fee',
  ADVISORY_FEE: 'Advisory fee',
  CAPITAL_GAIN: 'Capital gain',
  FLAT_RATE: 'Flat rate',
  CHOOSE_METHOD_TAXATION: 'Choose a method of taxation',
  TAXATION: 'Taxation',
  HOLDINGS: 'Holdings',
  LISTED_HOLDINGS: 'Listed Holdings',
  UNLISTED_HOLDINGS: 'Unlisted Holdings',
  EDIT_PORTFOLIO: 'Edit portfolio',
  DELETE_PORTFOLIO: 'Delete portfolio',
  CREATE_NEW_PORTFOLIO: 'Create New Portfolio',
  PORTFOLIO_NUMBER: 'Portfolio number',
  ENTER_PORTFOLIO_ID_FROM_FA: 'Portfolio ID from FA or arbitrary number.',
  EXPORT_PORTFOLIO: 'Export portfolio',
  EXPORT_PORTFOLIOS: 'Export portfolios',
  EXPORT_PORTFOLIO_PROMT: 'You are about to export this portfolio to Excel:',
  EXPORT_PORTFOLIOS_PROMT: 'You are about to export these portfolios to Excel:',
  EXPORT_PORTFOLIO_TO: 'Export to Excel portfolio',
  CHOOSE_PORTFOLIOS: 'Choose portfolios',
  CHOOSE_PORTFOLIO: 'Choose portfolio',
  SELECT_PORTFOLIO: 'Select Portfolio *',
  CHOOSE_CANDIDATE: 'Choose candidate',
  ADD_ADDITIONAL_STEPS: 'Add additional steps',
  MIN_MAX_CONSTRAINTS: 'Min/Max constraints',
  PORTFOLIO_CONSTRAINTS: 'Portfolio constraints',
  MINMAX: 'Min/Max',
  WITH_IMPORT_INSTRUMENT: 'Import portfolio from Excel',
  DOWNLOAD_THE_TEMPLATE_EXCEL: 'Download the template in Excel format',
  FAILED_TO_IMPORT: 'Failed to import',
  CONFIRM_DELETION: 'Confirm deletion',
  TEXT_DELETION: 'Are you sure you want to delete the portfolio',
  CLIENT_DELETION: 'Are you sure you want to delete client:',
  TRANSACTIONS: 'Transactions',
  BUY_TRANSACTIONS: 'Buy Transactions',
  SELL_TRANSACTIONS: 'Sell Transactions',
  TICKER: 'Ticker',
  OPTIMIZED_PORTFOLIO: 'Optimized Portfolio',
  CREATE_REPORT: 'Create Report',
  REPORT_TEXT: 'Do you want to create a report for this portfolio optimization?',
  FINISH: 'Finish',
  ALLOCATION: 'Allocation',
  GO_BACKWARD: 'Go backward',
  GO_FORWARD: 'Go forward',
  TEXT_DELETION_INSTRUMENT: 'Are you sure you want to delete the instrument:',
  TEXT_DELETION_REQUEST: 'Are you sure you want to delete the request:',
  CONNECT: 'Connect',
  CONNECT_INSTRUMENT: 'Connect instrument',
  MAP_INSTRUMENT: 'Select the instrument you want to map this request to:',
  SELECT_INSTRUMENT: 'Select instrument',
  SELECT_INSTRUMENT_FOR: 'Select instrument for mapping',
  TOTAL_FEE: 'Total fee',
  ACTIVATE_SYNCHRONIZATION: 'Active sync',
  LAST_SYNC: 'Last successful synchronization',
  PORTFOLIO_TYPE: 'Portfolio Type (Optimization)',
  CHOOSE_PORTFOLIO_TYPE: 'Choose Portfolio Type',
  PORTFOLIO_TYPE_ONE: 'Quant',
  PORTFOLIO_TYPE_TWO: 'Quant ESG',
  PORTFOLIO_TYPE_THREE: '4-Fund',
  OVERVIEW: 'Overview',
  ORDER_INSTRUCTIONS: 'Order Instructions',
  MONITORING: 'Monitoring',
  DOCUMENTS: 'Documents',
  CONSTRAINTS: 'Constraints',
  FIXATION: 'Fixation',
  CASH: 'Cash',
  FX_RATE: 'FX Rate',
  MIN: 'Min',
  MAX: 'Max',
  INSTRUMENTS: 'Instruments',
  NO_SHARES: 'No. of Shares',
  NO_SHARES_TO_FIX: 'No. of Shares To Fix',
  FIX: 'Fix all',
  BUY: 'Buy',
  SELL: 'Sell',
  UPDATE: 'Update',
  VALID_UNTIL: 'Valid until',
  UNTIL_CANCELED: 'Until canceled',
  SHARE_PR: '% Share',
  APPROVED_INSTRUMENT: 'Approved instrument',
  ADD_PORTFOLIO: 'Add Portfolio',
  OPTIMISE: 'Optimize',
  STANDING: 'Standing',
  RETURN_PER: 'Return %',
  VOLATILITY_PER: 'Volatility %',
  CVAR_PER: 'CVaR %',
  REBALANCE: 'Rebalance',
  GLOBAL_SHARES: 'Global shares',
  LIQUIDITY: 'Liquidity',
  START_OPTIMIZATION: 'Start Optimization',
  DISTRIBUTION: 'Distribution',
  ASSET_CLASS_ALLOCATION: 'Asset Class Allocation',
  VIEW_MORE: 'View more',
  ADD_CLIENT: 'Add client',
  INFORMATION: 'Information',
  FINANCIAL_PLANING: 'Financial Planing',
  PORTFOLIO_DETAILS: 'Portfolio Details',
  CREATED_ON: 'Created on',
  LAST_OPTIMIZED_ON: 'Last Optimized on',
  PERFORMANCE: 'Performance',
  THAN_LAST_MONTH: 'than last month',
  THAN_LAST_3_MONTHS: 'than last 3 months',
  THAN_LAST_YEAR: 'than last year',
  RESET: 'Reset',
  PORTFOLIO_OPTIMISE: 'Portfolio Optimize',
  PORTFOLIO_OPTIMISATION: 'Portfolio Optimization',
  CHANGE_SETTINGS: 'Change settings',
  BACK_TO_OPTIMISE: 'Back to Optimize',
  OPT_BREAK: 'You are about to leave the optimization flow. All entered information will be lost. Are you sure about this?',
  CLIENT_CREATION: 'Client creation',
  NOPORTFOLIO_TEXT: 'It looks like you do not have any portfolio, please edit your draft portfolio or create a new one',
  PORTFOLIO_CREATION: 'Portfolio Creation',
  MAIN_INFORMATION: 'Main Information',
  CLEAR: 'Clear',
  DETAIL_TEXT: 'Please fill in information to see portfolio performance',
  PRODUCT_NAME: 'Product name',
  OPTIMIZATION: 'Optimization',
  LIKE_TO_INCLUDE: 'Which fees would you like to include?',
  EDIT_ADVISOR_INFORMATION: 'Edit Advisor information',
  ADVISOR_INVITATION: 'Advisor invitation',
  WEIGHT: 'Weight',
  CASH_MANAGEMENT: 'Cash Management',
  MINIMUM_CASH_LEVEL: 'Minimum cash level',
  CASH_AMOUNT: 'Cash Amount',
  BY: 'by',
  EXCLUDE_HOLDINGS: 'Exclude holdings',
  EXCLUDE_ASSET_CLASS: 'Exclude Asset Class',
};
