import {
  CREATE_CLIENT_ACCOUNT,
  GET_USERS,
  GET_CLIENT,
  SET_CLIENT_TYPE,
  SET_CLIENT_INFORMATION,
  SET_CLIENT_ADDRESS,
  SET_CLIENT_SCORE,
  SET_CLIENT_PHONE,
  UPDATE_CLIENT_PHONE,
  DELETE_CLIENT_PHONE,
  UPDATE_CLIENT_INFO,
  SET_CLIENT_DATA,
  GET_CLIENT_DATA,
  SEND_INVITATION,
  GET_INVITATION_INFO,
  ACCEPT_INVITATION,
  SET_CLIENT_RISK_LEVEL,
  SEND_CLIENT_APPROVE,
  SET_CLIENT_FEES,
  GET_CLIENT_FEES,
  GET_REPRESENTATIVE,
  ADD_REPRESENTATIVE,
  UPDATE_REPRESENTATIVE,
  DELETE_REPRESENTATIVE,
  ADD_REPRESENTATIVE_DOC,
  DELETE_CLIENT,
} from './clientActionsTypes';

export const getUsers = () => ({
  type: GET_USERS.ACTION
});

export const createClient = (data) => ({
  type: CREATE_CLIENT_ACCOUNT.ACTION,
  payload: data
});
export const getClient = (data) => ({
  type: GET_CLIENT.ACTION,
  payload: data
});
export const setClientType = (data) => ({
  type: SET_CLIENT_TYPE.ACTION,
  payload: data
});
export const setClientInformation = (data) => ({
  type: SET_CLIENT_INFORMATION.ACTION,
  payload: data
});
export const updateClientInfo = (data) => ({
  type: UPDATE_CLIENT_INFO.ACTION,
  payload: data
});
export const addClientPhone = (data) => ({
  type: SET_CLIENT_PHONE.ACTION,
  payload: data
});
export const updateClientPhone = (data) => ({
  type: UPDATE_CLIENT_PHONE.ACTION,
  payload: data
});
export const deleteClientPhone = (data) => ({
  type: DELETE_CLIENT_PHONE.ACTION,
  payload: data
});
export const getRepresentative = (clientID) => ({
  type: GET_REPRESENTATIVE.ACTION,
  payload: clientID
});
export const addRepresentative = (data) => ({
  type: ADD_REPRESENTATIVE.ACTION,
  payload: data
});
export const updateRepresentative = (data) => ({
  type: UPDATE_REPRESENTATIVE.ACTION,
  payload: data
});
export const deleteRepresentative = (data) => ({
  type: DELETE_REPRESENTATIVE.ACTION,
  payload: data
});

export const addRepresentativeDoc = (data) => ({
  type: ADD_REPRESENTATIVE_DOC.ACTION,
  payload: data
});

export const setClientAddress = (data) => ({
  type: SET_CLIENT_ADDRESS.ACTION,
  payload: data
});
export const setClientMaritalStatus = (data) => ({
  type: SET_CLIENT_ADDRESS.ACTION,
  payload: data
});
export const setClientScore = (data) => ({
  type: SET_CLIENT_SCORE.ACTION,
  payload: data
});
export const setClientData = (data) => ({
  type: SET_CLIENT_DATA.ACTION,
  payload: data
});
export const getClientData = (data) => ({
  type: GET_CLIENT_DATA.ACTION,
  payload: data
});
export const sendInvitation = (data) => ({
  type: SEND_INVITATION.ACTION,
  payload: data
});
export const getInvitationInfo = (data) => ({
  type: GET_INVITATION_INFO.ACTION,
  payload: data
});
export const acceptInvitation = (data) => ({
  type: ACCEPT_INVITATION.ACTION,
  payload: data
});
export const setRiskLevel = (data) => ({
  type: SET_CLIENT_RISK_LEVEL.ACTION,
  payload: data
});
export const sendClientApprove = (data) => ({
  type: SEND_CLIENT_APPROVE.ACTION,
  payload: data
});
export const setClientFees = (data) => ({
  type: SET_CLIENT_FEES.ACTION,
  payload: data
});
export const getClientFees = (id) => ({
  type: GET_CLIENT_FEES.ACTION,
  payload: id
});
export const deleteClient = (id, history) => ({
  type: DELETE_CLIENT.ACTION,
  payload: { id, history }
});
