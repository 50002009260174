import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { Input } from 'antd';
import { ReactComponent as Search } from '../../assets/search-icon.svg';

import './style.scss';
// const { Option } = Select;

const CustomSearchInput = (props) => {
  const { setFocus } = props;
  const inputRef = useRef(null);
  const { width = '360px' } = props;

  useEffect(() => {
    if (setFocus && inputRef.current) {
      inputRef.current.focus({
        cursor: 'start',
      });
    }
  }, [setFocus]);

  const sharedProps = { ref: inputRef };

  return (
    <div style={{ position: 'relative', display: 'flex', justifyContent: 'flex-start' }}>
    <Input
      {...sharedProps}
      className="custom-input custom-search-input"
      style={{ width }}
      {...props}
    />
      <Search style={{ position: 'absolute', top: '5px', right: '16px' }}/>
    </div>
  );
};

export default CustomSearchInput;

CustomSearchInput.propTypes = {
  width: PropTypes.node,
  setFocus: PropTypes.bool,
};
