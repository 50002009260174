import React, { useState, useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ConfigProvider } from 'antd';

import StorageService from '../../services/StorageService';
import AuthRouting from './AuthRoutin';
import MainRouting from './MainRouting';

import './style.scss';
import SessionService from '../../services/SessionService';

const AppRouting = () => {
  const [isAuthenticated, setAuthenticated] = useState(
    StorageService.getJWTToken('acc') ||
    SessionService.getJWTToken('acc')
  );

  const advisor = useSelector((state) => state.userReducer.advisor);

  useEffect(() => {
    const token = StorageService.getJWTToken('acc') || SessionService.getJWTToken('acc');
    if (token) {
      setAuthenticated(token);
    } else {
      setAuthenticated('');
    }
  }, [advisor]);

  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#FB7B34',
            colorPrimaryBorder: '#0F0F0F',
          },
          components: {
            Layout: {
              headerBg: '#ffffff',
              headerColor: '#3C4043',
              headerHeight: 72,
              siderBg: '#FFFFFF',
              bodyBg: '#F6F7F9',
            },
            Menu: {
              collapsedIconSize: 0,
              itemSelectedBg: '#FD833A',
              itemActiveBg: '#F6F7F9',
              itemHeight: 48,
              itemMarginBlock: 8,
            },
            Switch: {
              handleBg: '#0F0F0F',
              trackHeight: 22,
              trackPadding: 1
            },
            Form: {
              labelColor: '#0F0F0F',
              labelFontSize: 14,
              itemMarginBottom: 20
            },
            Progress: {
              defaultColor: '#FB7B34',

            },
          },
        }}
      >
        {isAuthenticated ? <MainRouting /> : <AuthRouting />}
      </ConfigProvider>
    </BrowserRouter>
  );
};

export default AppRouting;
