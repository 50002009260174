import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Col, DatePicker, Row, Form, InputNumber, Input, Select } from 'antd';

import MainButton from '../../components/MainButton';
import { decimalSeparator } from '../../helpers/decimalSeparator';
import cross from '../../assets/cross.svg';
import Container from '../../components/Container';

const initVal = {
  type: '',
  description: '',
  amount: null,
  start: null,
  end: null
};

export const Withdrawal = ({ form }) => {
  const { t } = useTranslation('');
  const [typeArray, setTypeArray] = useState(form.getFieldValue('withdrawals'));

  const withdrawals = Form.useWatch('withdrawals');

  useEffect(() => {
    setTypeArray(form.getFieldValue('withdrawals'));
  }, [withdrawals]);

  const selectOptions = [
    {
      value: '',
      label: t('CHOOSE_TYPE'),
      disabled: true
    },
    {
      value: 2,
      label: t('ANNUAL'),
    },
    {
      value: 1,
      label: t('MONTHLY'),
    },
    {
      value: 0,
      label: t('ONE_TIME'),
    },
  ];

  const amountStyle = (name) => {
    const amount = withdrawals && withdrawals[name]?.amount;
    return `portfolio-form-select opt-select currency dig${amount?.length}`;
  };

  const handleChangeType = (event, index) => {
    setTypeArray(form.getFieldValue('withdrawals'));
  };

  const handleDelete = () => {
    setTypeArray(form.getFieldValue('withdrawals'));
  };

  const disabledYear = (current) => {
    const start = current < dayjs().startOf('year');
    const end = current <= dayjs().startOf('year');
    return { start, end };
  };

  const disabledMonth = (current) => {
    const start = current < dayjs().startOf('month');
    const end = current <= dayjs().startOf('month');
    return { start, end };
  };

  return (
    <Container id='financial-planning-withdrawal' className='portfolio-container  financial-planning'>
      <h2 className='portfolio-container-title text-left'>{t('WITHDRAWALS')}</h2>
      <div className='opt-portfolio-form-container'>
        <Row className='opt-portfolio-form-row row-header'>
          <Col span={4} className='opt-portfolio-form-col'>
            <label className='portfolio-form-label'>{t('TYPE')}*</label>
          </Col>
          <Col span={6} className='opt-portfolio-form-col'>
            <label className='portfolio-form-label'>{t('DESCRIPTION')}</label>
          </Col>
          <Col span={4} className='opt-portfolio-form-col'>
            <label className='portfolio-form-label'>{t('AMOUNT')}*</label>
          </Col>
          <Col span={4} className='opt-portfolio-form-col'>
            <label className='portfolio-form-label'>{t('START')}*</label>
          </Col>
          <Col span={4} className='opt-portfolio-form-col'>
            <label className='portfolio-form-label'>{t('END')}*</label>
          </Col>
          <Col span={2}></Col>
        </Row>
        <Form.List name='withdrawals'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row key={key} className='opt-portfolio-form-row'>
                <Col span={4} className='opt-portfolio-form-col'>
                  <Form.Item
                    {...restField}
                    name={[name, 'type']}
                    rules={[{
                      required: true,
                      message: `${t('SELECT_OPTION')}`,
                    }]}
                  >
                    <Select
                      placeholder='Choose alternative'
                      className='portfolio-form-select opt-select'
                      onChange={(event) => handleChangeType(event, name)}
                      options={selectOptions}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} className='opt-portfolio-form-col'>
                  <Form.Item {...restField} name={[name, 'description']}>
                    <Input
                      className='portfolio-form-select'
                      placeholder={t('ENTER_DESCRIPTION')}
                    />
                  </Form.Item>
                </Col>
                <Col span={4} className='opt-portfolio-form-col'>
                  <Form.Item
                    {...restField}
                    name={[name, 'amount']}
                    rules={[{
                      required: true,
                      message: `${t('ENTER_AMOUNT')}`,
                    }]}
                  >
                    <InputNumber
                      decimalSeparator={decimalSeparator()}
                      className={`${amountStyle(name)}`}
                      placeholder={t('ENTER_AMOUNT')}
                      max={999000000000000000}
                      formatter={(value) => value.toString()?.replace('.', decimalSeparator()).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }
                      parser={(value) => Number(value.replace(/ /g, '').replace(',', '.'))}
                    />
                  </Form.Item>
                </Col>
                <Col span={4} className='opt-portfolio-form-col'>
                  <Form.Item
                  {...restField}
                  name={[name, 'start']}
                  rules={[{
                    required: true,
                    message: `${t('ENTER_START')}`,
                  }]}
                  >
                    <DatePicker
                      disabled={!typeArray?.length || !typeArray[name]?.type === ''}
                      picker={typeArray[name]?.type === 1 ? 'month' : 'year'}
                      disabledDate={(current) => typeArray[name]?.type === 2 ? disabledYear(current).start : disabledMonth(current).start}
                      placeholder={t('ENTER_START')}
                      className='portfolio-form-select opt-select'
                    />
                  </Form.Item>
                </Col>
                <Col span={4} className='opt-portfolio-form-col'>
                  {typeArray && typeArray[name]?.type !== 0 &&
                  <Form.Item
                    {...restField}
                    name={[name, 'end']}
                    rules={[{
                      required: true,
                      message: `${t('ENTER_END')}`,
                    }]}
                  >
                    <DatePicker
                      disabled={!typeArray.length || !typeArray[name]?.type}
                      picker={typeArray[name]?.type === 1 ? 'month' : 'year'}
                      disabledDate={(current) => disabledYear(current).end}
                      placeholder={t('ENTER_END')}
                      className='portfolio-form-select opt-select'
                    />
                  </Form.Item>}
                </Col>
                  <Col span={2} className='opt-portfolio-form-action'>
                    <img
                      src={cross}
                      onClick={() => {
                        remove(name);
                        handleDelete();
                      }}
                    />
                  </Col>
                </Row>
              ))}
              <MainButton
                type='button'
                onClick={() => add(initVal)}
                className='add-deposit'
                >
                {t('ADD_WITHDRAWALS')}
              </MainButton>
            </>
          )}
        </Form.List>
      </div>
    </Container>
  );
};

Withdrawal.propTypes = {
  form: PropTypes.object,
};
