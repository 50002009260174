export const MyProfileSW = {
  SEND_INVITATION_TO_CLIENT: 'Skicka inbjudan till kunden för att få tillgång till kontot',
  SENDED_INVITATION: 'Aktiveringsmail har skickats. Skicka igen.',
  GO_TO_ONBOARDING: 'Gå till Onboarding of Client',
  UPDATE_MESSAGE_SUCCESS: 'Dina ändringar har sparats',
  UPDATE_MESSAGE_ERROR: 'Någonting gick fel',
  DEACTIVATE_ADVISOR: 'Inaktivera rådgivare',
  ACTIVATE_ADVISOR: 'Aktivera rådgivare',
  EDIT_ADVISOR: 'Redigera rådgivare',
  ENABLE_2FA: 'Aktivera tvåfaktorsautentisering',
  INSTRUCTION: 'För att få tillgång till hela funktionaliteten måste du ange och bekräfta BankID och/eller aktivera tvåfaktorsautentisering.',
  DELETE_PNUMBER: 'Radera personnummer',
  ALERT_ERROR: 'Fel. Du har inte verifierat ditt personnummer.',
  ALERT_SUCCESS: 'Lyckat. Du har verifierat ditt personnummer.',
  TITLE_2FA_MODAL: 'Konfigurera tvåfaktorsautentisering.',
  STEP_ONE_2FA_MODAL: 'Skaffa och installera Authenticator-app.',
  STEP_ONE_2FA_MODAL_TEXT: 'Authenticator-appar och webbläsartillägg som 1Password, Authy, Microsoft Authenticator, Google Authenticator, etc. genererar engångslösenord som används som en säkerhetsåtgärd för att verifiera din identitet när du uppmanas till det under inloggningen.',
  STEP_TWO_2FA_MODAL: 'Använd autentiseringsapp eller webbläsare för att skanna.',
  STEP_THREE_2FA_MODAL: 'Ange verifieringskod',
  STEP_THREE_2FA_MODAL_TEXT: 'När streckkoden ovan har skannats anger du den 6-siffriga verifieringskoden som genereras av appen.',
  TWO_FACTOR_ACTIVATE_BTN: 'Verifiera koden och aktivera',
  DELETE_CLIENT: 'Ta bort klient',
  CREATED_DATE: 'Skapat datum',
  ACTION: 'Handling',
  INVITATION: 'Inbjudan',
  ONBOARDING: 'Onboarding',
  UNSAVED_CHANGES: 'Osparade ändringar',
  YOU_WANT_WITHOUT_SAVING_ON: 'Du är på väg att lämna den här sidan utan att spara.',
  YOU_WANT_WITHOUT_SAVING_TWO: 'Alla ändringar kommer att gå förlorade. Vill du lämna utan att spara?',
  LEAVE_WITHOUT_SAVING: 'Lämna utan att spara',
  MODAL_DELETE_TITLE: 'Bekräfta radering',
  MODAL_DELETE_TEXT: 'Är du säker på att du vill ta bort rådgivare',
  MODAL_DELETE_TEXT_ASSET: 'Är du säker på att du vill ta bort tillgångsklass',
  MODAL_DELETE_TEXT_CLIENT: 'Är du säker på att du vill ta bort klienten',
  MODAL_DELETE_PORTFOLIO: 'Är du säker på att du vill ta bort portföljen:',
  ENGLISH: 'English',
  SVENSKA: 'Svenska',
  LANGUAGE: 'Språk',
};
