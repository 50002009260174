/* eslint-disable camelcase */
// import { v4 as uuidv4 } from 'uuid';

import { LOG_OUT } from '../user/userActionTypes';
import { RESET_ERROR } from '../helpers/auxiliaryActionTypes';
import {
  GET_USERS,
  CREATE_CLIENT_ACCOUNT,
  GET_CLIENT,
  SET_CLIENT_TYPE,
  SET_CLIENT_INFORMATION,
  SET_CLIENT_PHONE,
  SET_CLIENT_ADDRESS,
  SET_CLIENT_MARITAL,
  SET_CLIENT_SCORE,
  SET_CLIENT_DATA,
  UPDATE_CLIENT_INFO,
  DELETE_CLIENT_PHONE,
  UPDATE_CLIENT_PHONE,
  GET_CLIENT_DATA,
  SEND_INVITATION,
  GET_INVITATION_INFO,
  ACCEPT_INVITATION,
  SET_CLIENT_RISK_LEVEL,
  SEND_CLIENT_APPROVE,
  SET_CLIENT_FEES,
  GET_CLIENT_FEES,
  ADD_REPRESENTATIVE,
  UPDATE_REPRESENTATIVE,
  DELETE_CLIENT,
  UPDATE_CLIENT_INFO_CLEAR
} from './clientActionsTypes';

const initRepresentative = [{
  authority: [],
  personal_number: '',
  first_name: '',
  last_name: '',
  email: '',
  mobile_number: [{}],
}];
const initialState = {
  users: [],
  user: {
    company_representive: []
  },
  isLoading: false,
  isError: false,
  invitationInfo: '',
};

export const clientReducer = (state = initialState, action) => {
  // const updateUserData = (data) => {
  //   const { client: { email }, client: { first_name }, client: { last_name }, client: { phones } } = data;
  //   const phonesArray = phones.map(i => ({ ID: uuidv4(), phone: i }));
  //   return { ...state.user, email, firstName: first_name, lastName: last_name, phones: phonesArray };
  // };

  // const updateUsersList = (data) => {
  //   const { id } = data;
  //   const newUserData = updateUserData(data);
  //   const newUsersList = state.users?.list?.map(i => i.ID === +id ? newUserData : i);
  //   return newUsersList;
  // };
  const setClientBase = (data) => {
    const newRepresent = [];
    if (data.company_representive.length > 0) {
      data.company_representive.forEach(elem => {
        const representative = { ...elem };
        representative.authority = elem.authority ? JSON.parse(elem.authority) : [];
        representative.mobile_number = elem.mobile_number ? JSON.parse(elem.mobile_number) : [];
        newRepresent.push(representative);
      });
      data.company_representive = newRepresent;
    } else {
      data.company_representive = initRepresentative;
    }
    if (!data?.portfolio) {
      data.portfolio = state.users.portfolio;
    }
    return { ...state.users, ...data };
  };

  const deleteUser = (id) => {
    return state.users.list.filter(i => i.ID !== id);
  };

  switch (action.type) {
    case GET_USERS.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_USERS.SUCCESS:
      return {
        ...state,
        users: action.payload,
        isLoading: false,
        isError: false
      };

    case GET_USERS.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };
    case GET_CLIENT.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_CLIENT.SUCCESS:
      return {
        ...state,
        user: setClientBase(action.payload),
        // user: { ...state.user, ...action.payload },
        isLoading: false,
        isError: false
      };

    case GET_CLIENT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case CREATE_CLIENT_ACCOUNT.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case CREATE_CLIENT_ACCOUNT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        users: { list: [...state.users.list, action.payload] },
        user: action.payload
      };

    case CREATE_CLIENT_ACCOUNT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case SEND_INVITATION.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case SEND_INVITATION.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SEND_INVITATION.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case GET_INVITATION_INFO.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_INVITATION_INFO.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        invitationInfo: action.payload?.client?.email
      };

    case GET_INVITATION_INFO.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case ACCEPT_INVITATION.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ACCEPT_INVITATION.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case ACCEPT_INVITATION.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case SET_CLIENT_PHONE.REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case SET_CLIENT_PHONE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case SET_CLIENT_PHONE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case ADD_REPRESENTATIVE.REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ADD_REPRESENTATIVE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case ADD_REPRESENTATIVE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case UPDATE_REPRESENTATIVE.REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case UPDATE_REPRESENTATIVE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case UPDATE_REPRESENTATIVE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case SET_CLIENT_TYPE.REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case SET_CLIENT_TYPE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: { ...state.user, type: action.payload }
      };
    case SET_CLIENT_TYPE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case UPDATE_CLIENT_INFO.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case UPDATE_CLIENT_INFO.SUCCESS:
      return {
        ...state,
        isLoading: false,
        // users: { list: updateUsersList(action.payload) },
        // user: updateUserData(action.payload),
        isError: false,
        showMessage: { type: 'success' }
      };

    case UPDATE_CLIENT_INFO.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
        showMessage: { type: 'error' }
      };

    case UPDATE_CLIENT_INFO_CLEAR.ACTION:
      return {
        ...state,
        showMessage: null
      };

    case SET_CLIENT_ADDRESS.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case SET_CLIENT_ADDRESS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SET_CLIENT_ADDRESS.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case SET_CLIENT_MARITAL.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case SET_CLIENT_MARITAL.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SET_CLIENT_MARITAL.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case SET_CLIENT_SCORE.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case SET_CLIENT_SCORE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SET_CLIENT_SCORE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };
    case SET_CLIENT_INFORMATION.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case SET_CLIENT_INFORMATION.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SET_CLIENT_INFORMATION.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case SET_CLIENT_DATA.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case SET_CLIENT_DATA.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: {
          ...state.user,
          client_data: {
            ...state.user.client_data,
            [action.payload.type]: {
              data: action.payload.data,
              full_status: action.payload.full_status,
            }
          },
          recomented_risk_level: action.payload.risk_level,
        }
      };

    case SET_CLIENT_DATA.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case GET_CLIENT_DATA.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_CLIENT_DATA.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: {
          ...state.user,
          client_data: {
            ...state.user.client_data,
            [action.payload.type]: { data: action.payload.data, full_status: action.payload.full_status, risk_level: action.payload.risk_level }
          }
        }
      };

    case GET_CLIENT_DATA.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case UPDATE_CLIENT_PHONE.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case UPDATE_CLIENT_PHONE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case UPDATE_CLIENT_PHONE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case DELETE_CLIENT_PHONE.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case DELETE_CLIENT_PHONE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case DELETE_CLIENT_PHONE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case SET_CLIENT_RISK_LEVEL.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case SET_CLIENT_RISK_LEVEL.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SET_CLIENT_RISK_LEVEL.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case SEND_CLIENT_APPROVE.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case SEND_CLIENT_APPROVE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SEND_CLIENT_APPROVE.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case SET_CLIENT_FEES.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case SET_CLIENT_FEES.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };

    case SET_CLIENT_FEES.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case GET_CLIENT_FEES.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_CLIENT_FEES.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: {
          ...state.user,
          fees: action.payload
        }
      };

    case GET_CLIENT_FEES.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case DELETE_CLIENT.REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case DELETE_CLIENT.SUCCESS:
      return {
        ...state,
        users: { ...state.users, totalCount: state.users.totalCount - 1, list: deleteUser(action.payload) },
        isLoading: false,
      };

    case DELETE_CLIENT.ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload
      };

    case LOG_OUT:
      return {
        users: [],
        isLoading: false,
        isError: false
      };

    case RESET_ERROR:
      return {
        ...state,
        isError: false
      };

    default: {
      return state;
    }
  }
};
