import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import Report from '../Report';
import { useDispatch } from 'react-redux';
import { getAllPortfolio, clearPortfolioList } from '../../data/store/portfolio/portfolioActions';
import { getClient } from '../../data/store/clients/clientActions';
import FinancialPlanning from '../FinancialPlanning/FinancialPlanning';
import OrderInstructions from '../OrderInstructions/OrderInstructions';
import Monitoring from '../Monitoring/Monitoring';
import { StepRouter } from '../PortfolioOptimization/StepRouter';
import PortfolioRoute from '../Portfolio/PortfolioRoute';
import ClientSummary from '../Clients/ClientSummary';
import NewPortfolio from '../Clients/NewPortfolio';
import ProfileRouting from './ProfileRouting';

const ClientRouting = ({
  setUserId, needSave, setNeedSave, saveAlert,
  setSaveAlert, optAlert, setOptAlert, setOptStart
}) => {
  const { url } = useRouteMatch();
  const { userId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      dispatch(clearPortfolioList());
      dispatch(getAllPortfolio(userId));
      dispatch(getClient({ id: userId }));
      setUserId(+userId);
    }
  }, [userId]);

  return (
    <>
      <Switch>
        <Route path={`${url}/portfolio`} exact>
          <ClientSummary
            userId={userId}
          />
        </Route>
        <Route path={`${url}/new-portfolio`} exact>
          <NewPortfolio
            userId={userId}
          />
        </Route>
        <Route path={`${url}/portfolio/:portfolioId`}>
          <PortfolioRoute
            userId={userId}
            needSave={needSave}
            setNeedSave={setNeedSave}
            saveAlert={saveAlert}
            setSaveAlert={setSaveAlert}
          />
        </Route>
        <Route path={`${url}/optimization/:portfolioId`} >
          <StepRouter
            userId={userId}
            optAlert={optAlert}
            setOptAlert={setOptAlert}
            setOptStart={setOptStart}
          />
        </Route>
        <Route exact path={`${url}/fin-planning`}>
          <FinancialPlanning userId={userId}/>
        </Route>
        <Route exact path={`${url}/order-instructions`}>
          <OrderInstructions userId={userId}/>
        </Route>
        <Route exact path={`${url}/monitoring`}>
          <Monitoring id={userId}/>
        </Route>
        <Route exact path={`${url}/documents`}>
          <Report userId={userId}/>
        </Route>
        <Route path={`${url}/inform`}>
          <ProfileRouting
            userId={userId}
            setUserId={setUserId}
            needSave={needSave}
            setNeedSave={setNeedSave}
            saveAlert={saveAlert}
            setSaveAlert={setSaveAlert}
          />
        </Route>
      </Switch>
    </>
  );
};

export default ClientRouting;

ClientRouting.propTypes = {
  userId: PropTypes.string,
  setUserId: PropTypes.func,
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  optAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
  setOptAlert: PropTypes.func,
  setOptStart: PropTypes.func,
};
