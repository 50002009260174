/* eslint-disable camelcase */
import { axios } from '../utils/API';
import { API_URLS } from '../constants/api_urls';

export class PortfolioService {
  static getAll = async (clientId) => {
    return await axios.get(`${API_URLS.ALL_PORTFOLIO}/${clientId}/portfolio`, {
      params: { limit: -1, offset: 0 },
    });
  };

  static getById = async (portfolioId) => {
    return await axios.get(`${API_URLS.PORTFOLIO}/${portfolioId}`);
  };

  static getPortfolioHistory = async (portfolioId) => {
    return await axios.get(
      `${API_URLS.PORTFOLIO_HISTORY}/${portfolioId}/history`
    );
  };

  static createPortfolioFull = async (data) => {
    return await axios.post(API_URLS.CREATE_PORTFOLIO_FULL, {
      ...data,
      state: 'CURRENT',
    });
  };

  static createPortfolioDraft = async (values) => {
    return await axios.post(API_URLS.CREATE_PORTFOLIO_DRAFT, values);
  };

  static updatePortfolio = async (id, data) => {
    return await axios.put(`${API_URLS.UPDATE_PORTFOLIO}/${id}`, data);
  };

  static deleteById = async (id) => {
    return await axios.delete(`${API_URLS.DELETE_PORTFOLIO}/${id}`);
  };

  static search = async (value, type) => {
    return await axios.get(API_URLS.SEARCH, {
      params: { searchParam: value, limit: 20, offset: 0, include_in_ib: type },
    });
  };

  static portfolioExport = async (id) => {
    const format = { format: 'xlsx' };
    return await axios.post(
      `${API_URLS.EXPORT_PORTFOLIO}/${id}/export`,
      format,
      { responseType: 'arraybuffer' }
    );
  };

  static selectedPortfolioExport = async (data) => {
    const {
      format: { key },
      portfolio_ids,
    } = data;
    return await axios.post(
      `${API_URLS.SELECTED_EXPORT}`,
      { format: key, portfolio_ids },
      { responseType: 'arraybuffer' }
    );
  };

  static portfolioImport = async (file) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);

    return await axios.post(API_URLS.IMPORT_PORTFOLIO, bodyFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  static getReports = async (id) => {
    return await axios.get(`${API_URLS.REPORTS}/${id}/reports`, {
      params: { limit: 100, offset: 0 },
    });
  };

  static reportDownload = async (reportId) => {
    return await axios.get(`${API_URLS.DOWNLOAD_REPORT_V2}/${reportId}/download`, {
      responseType: 'arraybuffer',
    });
  };

  static reportCreate = async (data) => {
    const { id, name } = data;

    const bodyFormData = new FormData();
    const report = document.querySelector('#report');

    bodyFormData.append('portfolioID', id);
    bodyFormData.append('reportName', name);
    bodyFormData.append('file', report.files[0]);

    return await axios.post(API_URLS.CREATE_REPORT, bodyFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  static getHistoryById = async (id) => {
    return await axios.get(`${API_URLS.PORTFOLIO_HISTORY}/${id}/history`);
  };

  static optimizationPortfolio = async (id) => {
    return await axios.put(
      `${API_URLS.OPTIMIZATION}/${id}/requestOptimization`
    );
  };

  static portfolioTaskProspecting = async ({ portfolioId, data }) => {
    return await axios.post(
      `${API_URLS.OPTIMIZATION}/${portfolioId}/prospect`,
      data
    );
  };

  static portfolioTaskAnalysis = async ({
    portfolioId,
    name,
    fees,
    include_financial_plan,
  }) => {
    return await axios.get(
      `${API_URLS.OPTIMIZATION}/${portfolioId}/prospect/${name}/analysis`,
      { params: { ...fees, include_financial_plan } }
    );
  };

  static postStepOptimisationPortfolio = async ({ portfolioId, data }) => {
    return await axios.post(
      `${API_URLS.OPTIMIZATION}/${portfolioId}/optimisation/state`,
      data
    );
  };

  static getStepOptimisationPortfolio = async ({ portfolioId }) => {
    return await axios.get(
      `${API_URLS.OPTIMIZATION}/${portfolioId}/optimisation/state`
    );
  };

  static sendFinancialPlan = async ({ portfolioId, data }) => {
    return await axios.post(
      `${API_URLS.PORTFOLIO}/${portfolioId}/financial/plan`,
      data
    );
  };

  static portfolioFinalrequest = async (data) => {
    const { portfolioId, name, item } = data;
    return await axios.post(
      `${API_URLS.OPTIMIZATION}/${portfolioId}/prospect/${name}/apply`,
      item
    );
  };

  static sendDataPDFportfolio = async (data) => {
    const { portfolioId, array } = data;
    return await axios.post(
      `${API_URLS.OPTIMIZATION_V2}/${portfolioId}/optimisation/result`,
      array
    );
  };

  static downloadTEMP = async () => {
    return await axios.get(API_URLS.DOWNLOAD_TEMP, {
      responseType: 'arraybuffer',
    });
  };

  static getCandidates = async (portfolioId) => {
    return await axios.get(`${API_URLS.PORTFOLIO}/${portfolioId}/candidates`);
  };

  static getTransactions = async ({ portfolioId, candidatId }) => {
    return await axios.get(
      `/portfolio/${portfolioId}${API_URLS.CANDIDATE}/${candidatId}/transactions`
    );
  };
}
