import { put } from '@redux-saga/core/effects';
import {
  GET_USERS,
  GET_CLIENT,
  CREATE_CLIENT_ACCOUNT,
  SET_CLIENT_TYPE,
  SET_CLIENT_INFORMATION,
  UPDATE_CLIENT_INFO,
  SET_CLIENT_ADDRESS,
  SET_CLIENT_SCORE,
  SET_CLIENT_PHONE,
  SET_CLIENT_MARITAL,
  UPDATE_CLIENT_PHONE,
  DELETE_CLIENT_PHONE,
  SET_CLIENT_DATA,
  GET_CLIENT_DATA,
  SEND_INVITATION,
  GET_INVITATION_INFO,
  ACCEPT_INVITATION,
  SET_CLIENT_RISK_LEVEL,
  SEND_CLIENT_APPROVE,
  SET_CLIENT_FEES,
  GET_CLIENT_FEES,
  ADD_REPRESENTATIVE,
  UPDATE_REPRESENTATIVE,
  DELETE_REPRESENTATIVE,
  ADD_REPRESENTATIVE_DOC,
  DELETE_CLIENT,
} from './clientActionsTypes';
import { ClientService } from '../../../services/ClientService';
import { CLIENTS, LOGIN } from '../../../constants/routes';

export function * getUsers () {
  try {
    yield put({ type: GET_USERS.REQUEST });
    const response = yield ClientService.getAllClients();
    if (response.status === 200) {
      yield put({ type: GET_USERS.SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_USERS.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: GET_USERS.ERROR, payload: e.response.data.message });
  }
}
export function * getClient (action) {
  try {
    yield put({ type: GET_CLIENT.REQUEST });
    const response = yield ClientService.getClient(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_CLIENT.SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_CLIENT.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: GET_CLIENT.ERROR, payload: e.response.data.message });
  }
}

export function * createClientAccount (action) {
  try {
    const { client, history } = action.payload;
    const newClient = { ...client };
    // delete newClient.phone;
    yield put({ type: CREATE_CLIENT_ACCOUNT.REQUEST });
    const response = yield ClientService.createClients({ client: newClient });
    if (response.status === 200) {
      yield put({ type: CREATE_CLIENT_ACCOUNT.SUCCESS, payload: response.data.client });
      // yield put({ type: SET_CLIENT_PHONE.REQUEST });
      // const responsePhone = yield ClientService.addClientPhone({ id: response.data.client.ID, phone: client.phone });
      // if (responsePhone.status === 200) {
      //   yield put({ type: SET_CLIENT_PHONE.SUCCESS, payload: responsePhone.data });
      // } else {
      //   yield put({ type: SET_CLIENT_PHONE.ERROR, payload: responsePhone.response.data.message });
      // }
      history.push(`${CLIENTS}/${response.data.client.ID}/portfolio`);
    } else {
      yield put({ type: CREATE_CLIENT_ACCOUNT.ERROR, payload: response?.response?.data?.message });
    }
  } catch (e) {
    yield put({ type: CREATE_CLIENT_ACCOUNT.ERROR, payload: e?.response?.data?.message });
  }
}

export function * sendInvitation (action) {
  try {
    const { id, email } = action.payload;
    yield put({ type: SEND_INVITATION.REQUEST });
    const response = yield ClientService.sendInvitation({ id, email });
    if (response.status === 200) {
      yield ClientService.sendClientApprove({ id, status: 'CLIENT_STATUS_INVITED' });
      yield put({ type: SEND_INVITATION.SUCCESS });
      yield put({ type: GET_USERS.REQUEST });
      const resp = yield ClientService.getClient(id);
      if (resp.status === 200) {
        yield put({ type: GET_CLIENT.SUCCESS, payload: resp.data });
      } else {
        yield put({ type: GET_CLIENT.ERROR, payload: resp.response.data.message });
      }
    } else {
      yield put({ type: SEND_INVITATION.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: CREATE_CLIENT_ACCOUNT.ERROR, payload: e.response.data.message });
  }
}

export function * getInvitationInfo (action) {
  try {
    const { inviteHash } = action.payload;
    yield put({ type: GET_INVITATION_INFO.REQUEST });
    const response = yield ClientService.getInvitationInfo({ inviteHash });
    if (response.status === 200) {
      yield put({ type: GET_INVITATION_INFO.SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_INVITATION_INFO.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: GET_INVITATION_INFO.ERROR, payload: e.response.data.message });
  }
}
export function * acceptInvitation (action) {
  try {
    const { history } = action.payload;
    yield put({ type: ACCEPT_INVITATION.REQUEST });
    const response = yield ClientService.acceptInvitation(action.payload);
    if (response.status === 200) {
      yield put({ type: ACCEPT_INVITATION.SUCCESS });
      history.push(LOGIN);
    } else {
      yield put({ type: ACCEPT_INVITATION.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: ACCEPT_INVITATION.ERROR, payload: e.response.data.message });
  }
}

export function * setClientType (action) {
  try {
    yield put({ type: SET_CLIENT_TYPE.REQUEST });
    const response = yield ClientService.setClientType(action.payload);
    if (response.status === 200) {
      yield put({ type: SET_CLIENT_TYPE.SUCCESS, payload: response.data });
    } else {
      yield put({ type: SET_CLIENT_TYPE.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: SET_CLIENT_TYPE.ERROR, payload: e.response.data.message });
  }
}

export function * addClientScore (action) {
  try {
    yield put({ type: SET_CLIENT_SCORE.REQUEST });
    const response = yield ClientService.addClientScore(action.payload);
    if (response.status === 200) {
      yield put({ type: SET_CLIENT_SCORE.SUCCESS });
    } else {
      yield put({ type: SET_CLIENT_SCORE.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: SET_CLIENT_SCORE.ERROR, payload: e.response.data.message });
  }
}

export function * addClientPhone (action) {
  try {
    yield put({ type: SET_CLIENT_PHONE.REQUEST });
    const response = yield ClientService.addClientPhone(action.payload);
    if (response.status === 200) {
      yield put({ type: SET_CLIENT_PHONE.SUCCESS });
    } else {
      yield put({ type: SET_CLIENT_PHONE.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: SET_CLIENT_PHONE.ERROR, payload: e.response.data.message });
  }
}

export function * updateClientPhone (action) {
  console.log('🚀 ~ *updateClientPhone ~ action.payload:', action.payload);
  try {
    yield put({ type: UPDATE_CLIENT_PHONE.REQUEST });
    const response = yield ClientService.updateClientPhone(action.payload);
    if (response.status === 200) {
      yield put({ type: UPDATE_CLIENT_PHONE.SUCCESS });
    } else {
      yield put({ type: UPDATE_CLIENT_PHONE.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: SET_CLIENT_TYPE.ERROR, payload: e.response.data.message });
  }
}

export function * deleteClientPhone (action) {
  try {
    const response = yield ClientService.deleteClientPhone(action.payload);
    if (response.status === 200) {
      const response = yield ClientService.getClient(action.payload.id);
      if (response.status === 200) {
        yield put({ type: GET_CLIENT.SUCCESS, payload: response.data });
      } else {
        yield put({ type: GET_CLIENT.ERROR, payload: response.response.data.message });
      }
    } else {
      yield put({ type: DELETE_CLIENT_PHONE.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: DELETE_CLIENT_PHONE.ERROR, payload: e.response.data.message });
  }
}

export function * updateClientInfo (action) {
  const { history, path, id } = action.payload;
  try {
    yield put({ type: UPDATE_CLIENT_INFO.REQUEST });
    const response = yield ClientService.updateClientInfo(action.payload);
    if (response.status === 200) {
      yield put({ type: UPDATE_CLIENT_INFO.SUCCESS });
      yield put({ type: GET_CLIENT.ACTION, payload: { id } });
      yield put({ type: GET_USERS.ACTION });
      if (path) {
        history.push(path);
      }
    } else {
      yield put({ type: UPDATE_CLIENT_INFO.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: UPDATE_CLIENT_INFO.ERROR, payload: e.response.data.message });
  }
}

export function * updateClientAddress (action) {
  try {
    yield put({ type: SET_CLIENT_ADDRESS.REQUEST });
    const response = yield ClientService.setClientAddress(action.payload);
    if (response.status === 200) {
      yield put({ type: SET_CLIENT_ADDRESS.SUCCESS });
      const clientResponse = yield ClientService.getClient(action.payload.id);
      if (clientResponse.status === 200) {
        yield put({ type: GET_CLIENT.SUCCESS, payload: clientResponse.data });
      } else {
        yield put({ type: GET_CLIENT.ERROR, payload: clientResponse.response.data.message });
      }
    } else {
      yield put({ type: UPDATE_CLIENT_INFO.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: UPDATE_CLIENT_INFO.ERROR, payload: e.response.data.message });
  }
}

export function * setClientInformation (action) {
  try {
    const { id, client, history } = action.payload;
    const clienInfo = {
      id,
      client: {
        email: client?.email,
        firstName: client?.firstName,
        lastName: client?.lastName
      }
    };

    const clientAddress = {
      id,
      address: {
        client_id: +id,
        postal: client?.postal,
        zip_code: client?.zip_code,
        city: client?.city,
        careOf: client?.careOf ?? '',
      }
    };

    const clientMarital = {
      id,
      marital: {
        client_id: +id,
        marital_status: client?.marital_status,
        main_occupation: client?.main_occupation,
        children_on_support: client?.more_info
      }
    };

    const clientScore = {
      id,
      score: client?.score
    };

    yield put({ type: SET_CLIENT_INFORMATION.REQUEST });
    yield put({ type: UPDATE_CLIENT_INFO.REQUEST });
    const responseInfo = yield ClientService.updateClientInfo(clienInfo);
    yield put({ type: SET_CLIENT_ADDRESS.REQUEST });
    const responseAddress = yield ClientService.setClientAddress(clientAddress);
    yield put({ type: SET_CLIENT_MARITAL.REQUEST });
    const responseMarital = yield ClientService.setClientMaritalStatus(clientMarital);
    yield put({ type: SET_CLIENT_SCORE.REQUEST });
    const responseScore = yield ClientService.addClientScore(clientScore);
    if (responseInfo.status === 200) {
      // yield put({ type: UPDATE_CLIENT_INFO.SUCCESS });
    } else {
      yield put({ type: UPDATE_CLIENT_INFO.ERROR, payload: responseInfo.response.data.message });
    }
    if (responseAddress.status === 200) {
      // yield put({ type: SET_CLIENT_ADDRESS.SUCCESS });
    } else {
      yield put({ type: SET_CLIENT_ADDRESS.ERROR, payload: responseAddress.response.data.message });
    }
    if (responseMarital.status === 200) {
      // yield put({ type: SET_CLIENT_MARITAL.SUCCESS });
    } else {
      yield put({ type: SET_CLIENT_MARITAL.ERROR, payload: responseAddress.response.data.message });
    }
    if (responseScore.status === 200) {
      // yield put({ type: SET_CLIENT_SCORE.SUCCESS });
    } else {
      yield put({ type: SET_CLIENT_SCORE.ERROR, payload: responseScore.response.data.message });
    }
    yield put({ type: SET_CLIENT_INFORMATION.SUCCESS });
    yield history.push(`/dashboard/${id}`);
  } catch (e) {
    yield put({ type: SET_CLIENT_INFORMATION.ERROR, payload: 'SET_CLIENT_INFORMATION Error ' });
  }
}

export function * setClientData (action) {
  try {
    yield put({ type: SET_CLIENT_DATA.REQUEST });
    const response = yield ClientService.setClientData(action.payload);
    if (response.status === 200) {
      response.data?.success === true
        ? yield put({ type: SET_CLIENT_DATA.SUCCESS, payload: { ...action.payload.fields, risk_level: response.data?.risk_level ?? 0 } })
        : yield put({ type: SET_CLIENT_DATA.SUCCESS, payload: response.data });
    } else {
      yield put({ type: SET_CLIENT_DATA.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: SET_CLIENT_DATA.ERROR, payload: e.response.data.message });
  }
}

export function * getClientData (action) {
  try {
    yield put({ type: GET_CLIENT_DATA.REQUEST });
    const response = yield ClientService.getClientData(action.payload);
    if (response.status === 200) {
      const { data } = response;
      if (!data.type) {
        data.type = action.payload.name;
      }
      yield put({ type: GET_CLIENT_DATA.SUCCESS, payload: data });
    } else {
      yield put({ type: GET_CLIENT_DATA.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: GET_CLIENT_DATA.ERROR, payload: e.response.data.message });
  }
}

export function * setRiskLevel (action) {
  try {
    // const { id, history } = action.payload;
    yield put({ type: SET_CLIENT_RISK_LEVEL.REQUEST });
    const response = yield ClientService.setRiskLevel(action.payload);
    if (response.status === 200) {
      yield put({ type: SET_CLIENT_RISK_LEVEL.SUCCESS });
      // yield history.push(`/dashboard/${id}`);
    } else {
      yield put({ type: SET_CLIENT_RISK_LEVEL.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: SET_CLIENT_RISK_LEVEL.ERROR, payload: e.response.data.message });
  }
}

export function * sendClientApprove (action) {
  try {
    yield put({ type: SEND_CLIENT_APPROVE.REQUEST });
    const response = yield ClientService.sendClientApprove(action.payload);
    if (response.status === 200) {
      yield put({ type: SEND_CLIENT_APPROVE.SUCCESS });
    } else {
      yield put({ type: SEND_CLIENT_APPROVE.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: SEND_CLIENT_APPROVE.ERROR, payload: e.response.data.message });
  }
}

export function * setClientFees (action) {
  try {
    const { id, history } = action.payload;
    yield put({ type: SET_CLIENT_FEES.REQUEST });
    const response = yield ClientService.setClientFees(action.payload);
    if (response.status === 200) {
      yield put({ type: SET_CLIENT_FEES.SUCCESS });
      yield history.push(`/dashboard/${id}`);
    } else {
      yield put({ type: SET_CLIENT_FEES.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: SET_CLIENT_FEES.ERROR, payload: e.response.data.message });
  }
}

export function * getClientFees (action) {
  try {
    yield put({ type: GET_CLIENT_FEES.REQUEST });
    const response = yield ClientService.getClientFees(action.payload);
    if (response.status === 200) {
      yield put({ type: GET_CLIENT_FEES.SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_CLIENT_FEES.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: GET_CLIENT_FEES.ERROR, payload: e.response.data.message });
  }
}

export function * addRepresentative (action) {
  try {
    yield put({ type: ADD_REPRESENTATIVE.REQUEST });
    const response = yield ClientService.addRepresentative(action.payload);
    if (response.status === 200) {
      yield put({ type: ADD_REPRESENTATIVE.SUCCESS });
      if (response?.data && action.payload.docArray.length > 0) {
        const { id, representativeId, docArray } = action.payload;
        docArray.ForEach((item) => {
          addRepresentativeDoc(id, representativeId, item);
        });
      }
    } else {
      yield put({ type: ADD_REPRESENTATIVE.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: ADD_REPRESENTATIVE.ERROR, payload: e.response.data.message });
  }
}

export function * updateRepresentative (action) {
  try {
    yield put({ type: UPDATE_REPRESENTATIVE.REQUEST });
    const response = yield ClientService.updateRepresentative(action.payload);
    if (response.status === 200) {
      yield put({ type: UPDATE_REPRESENTATIVE.SUCCESS });
    } else {
      yield put({ type: UPDATE_REPRESENTATIVE.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: UPDATE_REPRESENTATIVE.ERROR, payload: e.response.data.message });
  }
}

export function * deleteRepresentative (action) {
  try {
    yield put({ type: DELETE_REPRESENTATIVE.REQUEST });
    const response = yield ClientService.deleteRepresentative(action.payload);
    if (response.status === 200) {
      yield put({ type: DELETE_REPRESENTATIVE.SUCCESS });
      const clientResponse = yield ClientService.getClient(action.payload.id);
      if (clientResponse.status === 200) {
        yield put({ type: GET_CLIENT.SUCCESS, payload: clientResponse.data });
      } else {
        yield put({ type: GET_CLIENT.ERROR, payload: clientResponse.response.data.message });
      }
    } else {
      yield put({ type: DELETE_REPRESENTATIVE.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: DELETE_REPRESENTATIVE.ERROR, payload: e.response.data.message });
  }
}

export function * addRepresentativeDoc (id, representativeId, item) {
  try {
    yield put({ type: ADD_REPRESENTATIVE_DOC.REQUEST });
    yield ClientService.addRepresentativeDoc({ id, representativeId, file: item.file, type: item.type });
  } catch (e) {
    yield put({ type: ADD_REPRESENTATIVE_DOC.ERROR, payload: e.response.data.message });
  }
}

export function * deleteClient (action) {
  const { id, history } = action.payload;
  try {
    yield put({ type: DELETE_CLIENT.REQUEST });
    const response = yield ClientService.deleteClient(id);
    if (response.status === 200) {
      yield put({ type: DELETE_CLIENT.SUCCESS, payload: +id });
      history.push('/clients');
    } else {
      yield put({ type: DELETE_CLIENT.ERROR, payload: response.response.data.message });
    }
  } catch (e) {
    yield put({ type: DELETE_CLIENT.ERROR, payload: e.response.data.message });
  }
}
