import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useLocation } from 'react-router';

import { getAdvisorById } from '../../data/store/user/userActions';
import { getAssets } from '../../data/store/instruments/instrumentsActions';

import { PrivateRoute } from '../../PrivateRouter';
import {
  INFORMATION,
  // REPORT,
  SUPPORT,
  DASHBOARD,
  ONBOARDING,
  MY_DETAILS,
  TEST,
  INCOME_AND_EXPENSES,
  FIXED_ASSETS,
  SCOPE_AND_INVESTABLE_CAPITAL,
  FINANCE_PLANERING,
  FINANCIAL_SITUATION,
  RISK_PROFILE,
  LEGAL_PLANNING,
  CUSTOMER_KNOWLEDGE,
  EXPERIENCE_BACKGROUND,
  TERMS,
} from '../../constants/routes';
import Support from '../Support';
import Information from '../Information';
import Profile from '../Profile';
// import AdminInstruments from '../AdminInstruments/AdminInstruments';
import Advisor from '../Advisors';
import { permissions } from '../../config/permissions';
// import TBDInstruments from '../../components/InstrumentsTBD';
import Dashboard from '../Dashboard/Dashboard';
import Onboarding from '../Onboarding';
import MyDetails from '../MyDetails';
import Test from '../Test/Test';
import IncomeAndExpenses from '../EkonomikRisk/IncomeAndExpenses';
import FixedAssets from '../EkonomikRisk/FixedAssets';
import ScopeAndInvestable from '../EkonomikRisk/ScopeAndInvestable';
import FinancePlannering from '../FinancePlannering';
import FinancialSituation from '../FinancialSituation';
import RiskProfile from '../RiskProfile';
import LegalNeedsPlanning from '../LegalNeedsPlanning/LegalNeedsPlanning';
import CustomerKnowledge from '../CustomerKnowledge/CustomerKnowledge';
import ExperienceBackground from '../ExperienceBackground/ExperienceBackground';
import TermsConditionsAgreement from '../TermsConditionsAgreement/TermsConditionsAgreement';
import { Layout } from 'antd';

import ErrorNotification from '../../components/ErrorNotification';
import SuccessNotification from '../../components/SuccessNotification';
import SideBar from '../../components/SideBar/SideBar';

import Routing from './Routing';
import { PrivateRouterWithProps } from '../../PrivateRouterWithProps';
import { getUsers } from '../../data/store/clients/clientActions';
// import NewInstrument from '../AdminInstruments/NewInstrument';
import AdvisorForm from '../../components/AdvisorForm/AdvisorForm';
import QuantRouting from './QuantRouting';

const { Content } = Layout;

const MainRouting = () => {
  const dispatch = useDispatch();
  const pathname = useLocation();
  // eslint-disable-next-line camelcase
  const { role, client_id } = useSelector((state) => state.userReducer.advisor);
  const [userId, setUserId] = useState(null);
  const [needSave, setNeedSave] = useState(false);
  const [saveAlert, setSaveAlert] = useState({ flag: false, path: '' });
  const [optStart, setOptStart] = useState(false);
  const [optAlert, setOptAlert] = useState({ flag: false, path: '' });

  let hasFetched = false; // FOR DEVELOPMENT ONLY

  useEffect(() => {
    setSaveAlert({ flag: false, path: '' });
  }, [pathname]);

  useEffect(() => {
    if (!hasFetched) {
      dispatch(getAssets());
      dispatch(getAdvisorById());
      dispatch(getUsers());
      hasFetched = true;
    }
  }, [dispatch]);

  const { SUPER_ADMIN, ADVISER, CLIENT, ADVISER_NOT_SECURE } =
    permissions.roles;

  return (
    <>
      {role
        ? (
        <Layout className="layout-main">
        <SideBar
          userId={userId}
          setUserId={setUserId}
          needSave={needSave}
          saveAlert={saveAlert}
          optAlert={optAlert}
          setSaveAlert={setSaveAlert}
          setOptAlert={setOptAlert}
          optStart={optStart}
        />
        <Layout>
          <Content className="content-row">
            <Switch>
              <PrivateRouterWithProps
                path="/clients"
                roles={[ADVISER, SUPER_ADMIN, CLIENT]}
                customProps={{
                  setUserId,
                  needSave,
                  setNeedSave,
                  saveAlert,
                  setSaveAlert,
                  optAlert,
                  setOptAlert,
                  setOptStart
                }}
                component={Routing}
              />
              <PrivateRouterWithProps
                path='/quant'
                roles={[SUPER_ADMIN]}
                customProps={{
                  needSave,
                  setNeedSave,
                  saveAlert,
                  setSaveAlert,
                }}
                component={QuantRouting}
              />
              <PrivateRouterWithProps
                path={'/advisors/edit-advisor/:userId'}
                roles={[SUPER_ADMIN]}
                component={AdvisorForm}
                customProps={{
                  needSave,
                  setNeedSave,
                  saveAlert,
                  setSaveAlert,
                }}
              />
              <PrivateRouterWithProps
                path={'/advisors/invite-advisor'}
                roles={[SUPER_ADMIN]}
                component={AdvisorForm}
                customProps={{
                  needSave,
                  setNeedSave,
                  saveAlert,
                  setSaveAlert,
                }}
              />
              <PrivateRoute
                path={'/advisors'}
                roles={[SUPER_ADMIN]}
                component={Advisor}
              />
              <PrivateRoute
                exact
                path={SUPPORT}
                roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                component={Support}
              />
              <PrivateRoute
                exact
                path={'/settings'}
                roles={[ADVISER, SUPER_ADMIN, CLIENT, ADVISER_NOT_SECURE]}
              >
                <Profile/>
              </PrivateRoute>
              <PrivateRoute
                exact
                path={INFORMATION}
                roles={[SUPER_ADMIN, ADVISER]}
                component={Information}
              />
              <PrivateRoute
                exact
                path={SUPPORT}
                roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                component={Support}
              />
              <PrivateRoute
                exact
                path={ONBOARDING}
                roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                component={Onboarding}
              />
              <PrivateRoute
                exact
                path={DASHBOARD}
                roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                component={Dashboard}
              />
              <PrivateRoute
                exact
                path={MY_DETAILS}
                roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                component={MyDetails}
              />
              <PrivateRoute
                exact
                path={TEST}
                roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                component={Test}
              />
              <PrivateRoute
                path={FINANCIAL_SITUATION}
                roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                component={FinancialSituation}
              />
              <PrivateRoute
                path={FINANCE_PLANERING}
                roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                component={FinancePlannering}
              />
              <PrivateRoute
                path={RISK_PROFILE}
                roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                component={RiskProfile}
              />
              <PrivateRoute
                path={LEGAL_PLANNING}
                roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                component={LegalNeedsPlanning}
              />
              <PrivateRoute
                path={CUSTOMER_KNOWLEDGE}
                roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                component={CustomerKnowledge}
              />
              <PrivateRoute
                path={EXPERIENCE_BACKGROUND}
                roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                component={ExperienceBackground}
              />
              <PrivateRoute
                exact
                path={TERMS}
                roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                component={TermsConditionsAgreement}
              />
              <PrivateRoute
                exact
                path={INCOME_AND_EXPENSES}
                roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                component={IncomeAndExpenses}
              />
              <PrivateRoute
                exact
                path={FIXED_ASSETS}
                roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                component={FixedAssets}
              />
              <PrivateRoute
                exact
                path={SCOPE_AND_INVESTABLE_CAPITAL}
                roles={[SUPER_ADMIN, ADVISER, CLIENT]}
                component={ScopeAndInvestable}
              />

              {role && role === 'CLIENT'
                ? (
                // eslint-disable-next-line camelcase
                <Redirect to={`/onboarding/${client_id}/set`} />
                  )
                : (
                <PrivateRoute
                  exact
                  path="*"
                  roles={[ADVISER, SUPER_ADMIN, CLIENT]}
                  component={Routing}
                >
                  <Redirect to={'/clients'} />
                </PrivateRoute>
                  )}
            </Switch>
          </Content>
          <ErrorNotification />
          <SuccessNotification />
        </Layout>
      </Layout>
          )
        : (
        <div />
          )}
    </>
  );
};

export default MainRouting;
